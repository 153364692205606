<template>
  <layout-navigation />
</template>

<script>
import LayoutNavigation from '@/layouts/navigation.vue';

export default {
  components: {
    LayoutNavigation,
  },
};
</script>
